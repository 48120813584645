<template>
  <div></div>
</template>
<script>
export default {
  name: "Aktivasi",
  created() {
    this.aktivasi();
  },
  methods: {
    aktivasi() {
      this.$store
        .dispatch("auth/activation", {
          token: this.$route.params.kode
        })
        .then(succ => {
          this.$alert(succ.message, "Success", "success");
          this.$router.push("/login");
        })
        .catch(err => {
          this.$alert(err.response.data, "Error", "error");
        });
    }
  }
};
</script>
